.main-footer {
  background-color: var(--wg-dark-blue-color);
  section {
    * {
      z-index: 4;
    }
  }
}

.img-deco {
  display: flex;
  position: relative;
  max-width: 856px;
  max-height: 263px;
  top: 0;
  left: 0;

  img {
    width: 100%;
    height: auto;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
  }
}

.padding-left-10 {
  padding-left: 10px;
}

.footer-contact-link {
  cursor: pointer;
  text-decoration: none !important;
  align-items: center;
  color: white !important;
  &:hover {
    color: var(--wg-primary-color) !important;
    -webkit-transition: color 300ms;
    -ms-transition: color 300ms;
    transition: color 300ms;
  }
}

.footer-contact-unlink {
  align-items: center;
  color: white !important;
  text-decoration: none !important;
  cursor: default;
}

.footer-contact-info {
  color: white !important;
  display: flex;
}

a.footer-page-link {
  display: block;
  float: left;
  clear: both;
  text-decoration: none !important;
  color: var(--wg-light-color) !important;
  padding-bottom:1px;
  background-image: linear-gradient(var(--wg-primary-color) 0 0);
  background-position: 0 100%;
  background-size: 0% 2px;
  background-repeat: no-repeat;
  transition: background-size 0.3s,  background-position 0s 0.3s;

  &:hover {
    color: var(--wg-primary-color) !important;
    -webkit-transition: color 300ms, background-position 0s 0.3s, background-size 0.3s;
    -ms-transition: color 300ms, background-position 0s 0.3s, background-size 0.3s;
    transition: color 300ms, background-position 0s 0.3s, background-size 0.3s;
    background-position: 100% 100%;
    background-size: 100% 2px;
  }
}

.logo-size {
  width: 60px;
  height: 60px;
}

.underline-styled {
  width: 30px;
  background-color: var(--wg-primary-color) !important;
  height: 4px;
  border-radius: 3px;
  opacity: 1;
  border: none;
}

.copyright-styled {
  background-color: var(--wg-dark-blue-color) !important;
  border-top: 1px solid #272a68;
}
