.detail-group {
  .detail-group__icon {
    transform: rotateY(0deg);
    transition: transform 1s cubic-bezier(0.38, 0.12, 0, 1);
  }
}
.detail-group:hover {
  .detail-group__icon {
    transform: rotateY(-180deg);
  }
}

.detail-group__icon-box {
  display: flex;
  justify-content: center;
  align-items: start;
  height: 100%;
}

$ratio: 1.61803398875 * 2;
$iconSize: 20px;
$iconPadding: round($iconSize * $ratio);
.detail-group__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: $iconPadding;
  height: $iconPadding;
  //   padding: $iconPadding;
  background-color: var(--wg-light-color);
  border-radius: 50%;

  i {
    font-size: $iconSize;
    color: var(--wg-secondary-color);
  }
}

.detail-group__text-box {
  background-color: #f8f8fa;
  border-radius: var(--wg-border-radius);
  color: var(--wg-text-body);
  h5 {
    font-weight: 700;
    color: var(--wg-secondary-color);
  }

  p {
    font-weight: 500;
  }
  a {
    color: var(--wg-text-body);
    text-decoration: none;
    transition: color 0.8s cubic-bezier(0.03, 0.81, 0.09, 1);
  }
  a:hover {
    color: var(--wg-primary-color);
  }
}
