$footerHeight: 561px;
.contact-us__container {
  width: 100%;
  min-height: calc(100vh - $footerHeight);
}

.contact-us__mapframe {
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--wg-border-radius);
  box-shadow: 2px 4px 12px rgba($color: #000000, $alpha: 0.08);
  iframe {
    border-radius: var(--wg-border-radius);
    width: 100%;
    height: 549px;
  }
}
.contact-us__detail {
  border-radius: var(--wg-border-radius);
  background-color: #fff;
  box-shadow: 2px 4px 12px rgba($color: #000000, $alpha: 0.08);
  margin-trim: block;
  h2 {
    font-size: 30px;
    font-weight: 700;
  }
}

.contact-us__img-deco {
  display: flex;
  position: relative;
  z-index: -1;

  max-width: 856px;
  max-height: 263px;

  img {
    width: 100%;
    position: absolute;
    top: 100px;
    right: -300px;
  }
}

.copy {
  cursor: pointer;
  transition: color 0.8s cubic-bezier(0.03, 0.81, 0.09, 1);
}
.copy:hover {
  color: var(--wg-primary-color);
}
