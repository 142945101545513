.bullet-list {
  width: 100%;
  list-style: none;
  padding: 0px;

  li {
    display: flex;
    flex-grow: 1;
    align-items: center;

    font-size: 20px;
    font-weight: 500;
    color: var(--wg-theme-secondary);
    margin-bottom: 10px;

    span {
      display: flex;
      justify-content: flex-start;
      align-self: flex-start;
      align-items: center;
      min-width: 190px;
      margin-right: 10px;

      i {
        display: flex;
        flex: 0 0 auto;
        align-items: center;
        justify-content: center;
        color: var(--wg-primary-color);
        margin-right: 10px;
        transform: translateY(1px);
      }
    }
  }
}

.title {
  margin-bottom: 15px;
  font-size: 40px;
}

// Botton //
$btnWidth: 400px;
$btnSize: 10px;

.btn-pill {
  text-decoration: none;
  padding: $btnSize ($btnSize + 10px);
  border-radius: 999px;
  font-weight: 500;
}
