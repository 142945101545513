$wg-text-body-color: #3e4073 !default;
$wg-primary-color: #f7a400 !default;
$wg-secondary-color: #14176c !default;
$wg-theme-secondary: #191d88 !default;
$wg-dark-blue-color: #0e104b !default;
$wg-light-color: #ecf6fa !default;
$wg-yellow-light-color: #fffbf4 !default;
$wg-gray-1: #e6eeff !default;
$wg-gray-2: #b8b9da !default;
$wg-gray-3: #818298 !default;

$wg-border-radius: 10px !default;

$theme-colors: (
  "primary": $wg-primary-color,
  "secondary": $wg-secondary-color,
  "light": $wg-light-color,
);

:root {
  --wg-text-body: #{$wg-text-body-color};
  --wg-primary-color: #{$wg-primary-color};
  --wg-theme-secondary: #{$wg-theme-secondary};
  --wg-secondary-color: #{$wg-secondary-color};
  --wg-dark-blue-color: #{$wg-dark-blue-color};
  --wg-light-color: #{$wg-light-color};
  --wg-yellow-light: #{$wg-yellow-light-color};
  --wg-border-radius: #{$wg-border-radius};
  --wg-gray-1: #{$wg-gray-1};
  --wg-gray-2: #{$wg-gray-2};
  --wg-gray-3: #{$wg-gray-3};
}

.btn-primary,
.btn-secondary {
  color: #fff !important;
}

.wg-btn-primary {
  background-color: $wg-primary-color;
  color: #fff;
  transition: background-color 360ms ease;
}
.wg-btn-primary:hover {
  background-color: $wg-theme-secondary;
}

.wg-text-body {
  color: var(--wg-text-body);
}

.swal2-confirm {
  background-color: $wg-primary-color !important;
  color: #fff;
}

.grecaptcha-badge {
  z-index: 99 !important;
  bottom: 80px !important;
  transform: scale(0.9) !important;
}

.fadeInUp-animation {
  animation: 800ms fadeInUp forwards;
  animation-timing-function: cubic-bezier(0, 0, 0.5, 1);
}

.animate {
  opacity: 0;
}

@keyframes fadeInUp {
  from {
    transform: translateY(25%);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

* {
  font-family: "Prompt", sans-serif !important;
}

p {
  letter-spacing: 1px !important;
}

.img-frame {
  width: 100%;
  border-radius: var(--wg-border-radius);
}

.safari-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "../node_modules/bootstrap-icons/font/bootstrap-icons.scss";
