.scrollTopBtnDisplay {
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 45px;
  height: 45px;
  background-color: var(--wg-primary-color);
  border-radius: 5px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  z-index: 99;

  &:hover {
    background-color: var(--wg-theme-secondary);
    -webkit-transition: background-color 300ms linear;
    -ms-transition: background-color 300ms linear;
    transition: background-color 300ms linear;
    opacity: 0.7;
  }
}

.scrollTopBtnDisplay {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scrollTopBtnHide {
  display: none;
}
