.our-feature__item {
  display: flex;
  align-items: center;
  gap: 16px;

  color: var(--wg-secondary-color);
  font-weight: 500;
  font-size: 22px;

  i {
    transform: translateY(3px);
  }
}
