.test-var {
  color: var(--wg-primary-color);
}

.imageCover {
  width: 100%;
  height: 100%;
  display: block;

  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;

  background-image: url("../../assets/img/quotation/side_banner.png");
  background-repeat: no-repeat;
  // background-position: center;
  background-size: cover;
  background-color: white;

  img {
    width: 200px;
    height: 200px;
    object-fit: cover;

    filter: drop-shadow(2px 4px 3px rgba($color: #000000, $alpha: 0.18));
  }
}

$footerHeight: 561px;
.quotation-container {
  min-height: calc(100vh - $footerHeight);
}
