.fixed-navbar-height {
    padding-top: 137px !important;
}

@media only screen and (max-width: 1200px) {
    .fixed-navbar-height {
        padding-top: 183px !important;
    }
}

@media only screen and (max-width: 767px) {
    .fixed-navbar-height {
        padding-top: 86px !important;
    }
}