$iconSize: 80px;

.feature-card {
  width: 100%;
  height: 100%;
  padding: 30px;
  background-color: var(--wg-yellow-light);
  border: 1px solid #fbd58a;
  border-radius: var(--wg-border-radius);

  .icon {
    width: $iconSize;
    height: $iconSize;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--wg-primary-color);
    color: var(--bs-white);
    margin: 0 auto 30px;
    border-radius: 50%;

    i {
      transform: rotateY(0deg);
      transition: transform 380ms ease-out 0s;
      font-size: $iconSize/2;
    }
  }

  .feature-card__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    word-wrap: break-word;
  }
}

.feature-card__title {
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 600;
}

.feature-card:hover {
  .icon {
    i {
      transform: rotateY(-180deg);
    }
  }
}
