.privacy-main-body {
    background-color: white;
    margin-top: 50px;
    margin-bottom: 50px;
    color: var(--wg-text-body);
}

.privacy-topic-container {
    margin-bottom: 50px;
}

.privacy-header-text {
    h2 {
        font-weight: 500;
    }
}

.privacy-indent {
    padding-left: 30px;
}