.navbar {
    padding: 0px !important;
    background-color: white !important;
    box-shadow: 2px 6px 12px rgba($color: #000000, $alpha: 0.08);
}

.logo-size {
    width: 80px !important;
    height: 80px !important;
}

.logo-size-mobile {
    width: 70px !important;
    height: 70px !important;
}

.logo-padding {
    padding-left: 16px;
}

.extra-logo {
    padding-left: 25px;
}

.extra-logo-size {
    width: 60px;
    height: 60px;
}

.logo-container {
    border-right: 1px solid lightgray;
    padding: 29px 80px 28px 80px !important;
}

.top-right {
    border-bottom: 1px solid lightgray;
    flex-wrap: wrap;
}

.header-contact-container1 {
    justify-content: start;
}

.header-contact-container2 {
    justify-content: end;
}

.nav-menu {
    color: var(--wg-primary-color) !important;
}

.border-left-grey {
    border-left: 1px solid lightgray;
}

.nav-group {
    justify-content: center;
}

.nav-button {
    align-self: center;
    padding: 20px !important;
}

.nav-button-text {
    cursor: pointer;
    text-decoration: none !important;
    color: var(--wg-text-body) !important;
    &:hover {
        color: var(--wg-primary-color) !important;
        -webkit-transition: color 500ms;
        -ms-transition: color 500ms;
        transition: color 500ms;
    }
}

.nav-button-text-active {
    cursor: pointer;
    text-decoration: none !important;
    color: var(--wg-primary-color) !important;
}

.nav-action {
    cursor: pointer;
    color: var(--wg-text-body) !important;
    padding: 30px !important;
    &:hover {
        color: var(--wg-primary-color) !important;
        -webkit-transition: color 500ms;
        -ms-transition: color 500ms;
        transition: color 500ms;
    }
}

.quotation-button {
    display: flex;
    background-color: var(--wg-primary-color);
    height: 45px;
    width: 200px;
    justify-content: center;
    align-items: center;
    border-radius: 999px;
    color: white;
    cursor: pointer;
}

.quotation-text {
    margin-bottom: 4px;
}

.padding-0 {
    padding: 0px !important;
}

.padding-left-10 {
    padding-left: 10px;
}

.padding-left-25 {
    padding-left: 25px;
}

.header-contact-link {
    cursor: pointer;
    text-decoration: none !important;
    color: var(--wg-text-body) !important;
    &:hover {
        color: var(--wg-primary-color) !important;
        -webkit-transition: color 300ms;
        -ms-transition: color 300ms;
        transition: color 300ms;
    }
}

.header-contact-unlink {
    align-items: center;
    color: var(--wg-text-body) !important;
    text-decoration: none !important;
    cursor: default;
}

.header-contact-info {
    color: var(--wg-text-body) !important;
    display: inline-block;
    align-items: center;
}

.navmenu-offcanvas-body {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.navmenu-pagelink-group {
    padding: 15px;
}

.navmenu-pagelink-container {
    border-bottom: 1px solid lightgray;
}

.navmenu-pagelink-container-active {
    border-bottom: 1px solid lightgray;
    border-left: 3px solid var(--wg-primary-color) !important;
}

.navmenu-pagelink-button {
    padding-left: 16px;
    color: var(--wg-text-body) !important;
    cursor: pointer;
    text-decoration: none !important;
}

.navmenu-pagelink-button-active {
    padding-left: 16px;
    color: var(--wg-primary-color) !important;
    cursor: pointer;
    text-decoration: none !important;
}

.navmenu-info {
    color: var(--wg-text-body) !important;
}

.address-offcanvas-body {
    color: var(--wg-text-body) !important;
    padding: 0px 16px 16px 16px !important;
}

.address-button-close {
    filter: invert(58%) sepia(29%) saturate(4364%) hue-rotate(13deg) brightness(111%) contrast(106%);
}

.address-hr {
    background-color: var(--wg-primary-color) !important;
    height: 1px;
    border: none;
    opacity: 1;
    margin: "0px 15px";
}

@media only screen and (max-width: 1200px) {
    .header-contact-container1,
    .header-contact-container2 {
        justify-content: center !important;
    }
    .logo-size {
        width: 70px;
        height: 70px;
    }
    .logo-padding {
        padding: 8px; 
    }
}
