.back-drop {
  width: 100%;
  height: 100vh;
  min-width: 100vh;
  z-index: 1200;

  position: absolute;
  top: 0;
  left: 0;
}

.loading-box {
  display: block;
  width: 100vw;
  height: 100vh;
  padding: 35px;
  background-color: rgba($color: #000000, $alpha: 0.7);
  text-align: center;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1920;
}

.spinner-container {
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-spinner {
  width: 3.8rem !important;
  height: 3.8rem !important;
}
