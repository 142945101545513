.list-menu {
  width: 100%;
  list-style: none;
  padding: 0px;
  margin: 0px;

  li {
    display: flex;

    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-grow: 1;
      padding: 10px 20px;

      color: var(--wg-text-body);
      background-color: var(--bs-white);
      border-radius: var(--wg-border-radius);
      font-weight: 500;
      transition: 360ms ease;
      span {
        display: flex;
        justify-content: flex-start;
        align-self: center;
        margin-bottom: 0px;
      }

      .title {
        font-size: 20px;
        font-weight: 600;
      }

      .sub-title {
        font-size: 13px;
        font-weight: 500;
        color: var(--wg-gray-3);
      }
    }

    a:hover {
      * {
        color: var(--bs-white);
      }
      .sub-title {
        color: var(--bs-white);
      }
      i {
        color: var(--wg-primary-color);
      }
      background-color: var(--wg-theme-secondary);
    }
  }

  .item-mb {
    margin-bottom: 10px;
  }
}
