.header-box {
  padding-top: 105px;
  padding-bottom: 110px;

  h2 {
    font-size: 48px;
    font-weight: 700;
    color: var(--wg-secondary-color);
    vertical-align: bottom;
    margin-bottom: 0px;
  }

  p {
    color: var(--wg-text-body);
  }
}

.detail-area {
  padding: 100px 0px;
}

.title {
  font-size: 40px;
  font-weight: 700;
}

.feature_title {
  margin-bottom: 15px;
  font-size: 40px;
  font-weight: 600;
}
