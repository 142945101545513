.main-body {
  background-color: white;
  min-height: 205px;
}

.banner-text-title {
  color: white;
  h1 {
    font-size: 6rem;
    font-weight: 600;
  }
}

.banner-text-subtitle {
  color: white;
  h5 {
    font-size: 1.8rem;
  }
}

.carousel-area {
  display: block;
  width: 100%;
  height: auto;
}

.carousel-image-container {
  background-image: url("../../assets/img/home/header_banner.png");
  background-size: cover;
  background-position: 53% center;
  height: 570px;
  align-content: center;
  justify-content: center;
}

.features-area {
  display: block;
  width: 100%;
}

.features_container_wrap {
  border-bottom: 1px solid var(--wg-gray-1);
  box-sizing: border-box;
}

.row {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.feature-item {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 20px;
  padding: 50px;
  border-right: 1px solid var(--wg-gray-1);
  min-height: 250px;
  .icon-animated {
    transition: 380ms ease-out 0s;
    transform: rotateY(0deg);
  }
}

.feature-item:hover .icon-animated {
  transform: rotateY(-180deg);
}

.feature-icon {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--wg-light-color);
  border-radius: 10px;
  flex: 0 0 auto;
  color: var(--wg-secondary-color);
}

.feature-content {
  color: var(--wg-text-body);
  p {
    margin: 0;
  }
}

.details-area {
  display: block;
  width: 100%;
  padding: 90px 0px 60px;
}

.services-area {
  display: block;
  width: 100%;
  background: var(--wg-secondary-color);
  position: relative;
  z-index: 1;
  padding: 120px 0 90px;
}

.service-decoration-img {
  display: flex;
  position: relative;
  max-width: 400px;
  img {
    width: 100%;
    height: auto;
    position: absolute;
    z-index: -1;
    bottom: -90px;
    left: 0;
  }
}

.service-decoration-img2 {
  display: flex;
  position: relative;
  img {
    max-width: 100%;
    height: auto;
    position: absolute;
    z-index: -1;
    top: -120px;
    right: 0;
  }
}

.service-headtext {
  margin-bottom: 50px;
  span {
    color: var(--wg-primary-color);
    font-weight: bold;
  }
  h2 {
    color: var(--wg-light-color);
    font-weight: bolder;
    font-size: 40px;
  }
}

.service-item {
  border: 2px solid var(--wg-light-color);
  border-radius: 10px;
  padding: 35px;
  margin-bottom: 30px;
  background: var(--wg-secondary-color);
  min-height: 300px;
  .icon-animated {
    transition: 380ms ease-out 0s;
    transform: rotateY(0deg);
  }
}

.service-item:hover .icon-animated {
  transform: rotateY(-180deg);
}

.service-icon-container {
  color: var(--wg-primary-color);
  margin-bottom: 20px;
}

.service-content {
  display: block;
  color: var(--wg-light-color);
  h4 {
    margin-bottom: 20px;
    font-weight: bold;
  }
  p {
    margin: 0;
    color: var(--wg-light-color);
  }
}

.service-content-title {
  min-height: 80px;
}

.contact-area {
  display: block;
  position: relative;
  padding: 120px 0;
  z-index: 1;
}

.contact-bg-wrap {
  clip: rect(0, auto, auto, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.contact-image {
  display: block;
  background-image: url("../../assets/img/home/contact_bg2.png");
  background-size: cover;
  background-position: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.contact-content {
  display: block;
  background: var(--wg-secondary-color);
  padding: 60px 60px;
  border-radius: 15px;
}

.contact-title {
  color: var(--wg-light-color);
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: 400;
  text-transform: capitalize;
  span {
    font-weight: 700;
  }
}

.contact-phone {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
}

.contact-phone-icon {
  color: var(--wg-primary-color);
}

.contact-number {
  display: block;
  span {
    line-height: 1;
    display: block;
    font-size: 14px;
    color: var(--wg-gray-1);
    text-align: left;
  }
  a {
    text-align: left;
    text-decoration: none;
    font-size: 24px;
    color: var(--wg-light-color);
    font-weight: 600;
    outline: none;
  }
}

.trial-link {
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 999px;
  font-weight: 500;
}

.brands-area {
  display: block;
  min-height: 230px;
  align-content: center;
}

.brand-swiper {
  width: 100%;
  height: 100%;
}

.team-swiper {
  width: 70%;
  height: 100%;
}

.swiper-slide {
  background: white;
  display: block;
  align-self: center;
  img {
    max-width: 100%;
  }
}

.brand-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-area {
  padding: 60px 0px;
  background-color: var(--wg-gray-1);
}

.team-intro {
  padding-bottom: 20px;
}

.team-intro1 {
  text-align: center;
  color: var(--wg-primary-color);
  text-transform: uppercase;
  font-weight: bold;
}

.team-intro2 {
  text-align: center;
  color: var(--wg-secondary-color);
  text-transform: capitalize;
  font-weight: bold;
}

.team-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--wg-gray-1);
  min-height: 270px;
}

.team-member-img {
  width: 200px;
  height: 250px;
  border-radius: 10px;
}

.team-member-img2 {
  width: 200px;
  height: 250px;
  border-radius: 10px;
  object-fit: cover;
}

.team-position-container {
  text-align: center;
  padding: 20px 0px;
  min-height: 130px;
  background-color: var(--wg-gray-1);
  h4 {
    color: var(--wg-secondary-color);
    font-weight: 600;
  }
  h6 {
    color: var(--wg-primary-color);
    font-size: 18px;
  }
}

@media only screen and (max-width: 1400px) {
  .feature-item {
    padding: 60px 20px;
    min-height: 260px;
  }
  .service-item {
    min-height: 320px;
  }
}

@media only screen and (max-width: 1200px) {
  .features_container_wrap {
    border-bottom: none;
  }
  .feature-item {
    border-right: 1px solid var(--wg-gray-1);
    border-bottom: 1px solid var(--wg-gray-1);
    min-height: 230px;
  }
  .team-swiper {
    width: 80%;
  }
  .service-item {
    min-height: 300px;
  }
}

@media only screen and (max-width: 992px) {
  .carousel-image-container {
    height: 400px;
  }
  .banner-text-title {
    h1 {
      font-size: 5rem;
    }
  }
  .banner-text-subtitle {
    h5 {
      font-size: 1.5rem;
    }
  }
}

@media only screen and (max-width: 767px) {
  .feature-item {
    border-right: none;
    border-bottom: 1px solid var(--wg-gray-1);
    min-height: 200px;
  }
  .team-position-container {
    min-height: 175px;
  }
  .team-swiper {
    width: 90%;
  }
  .carousel-text-container {
    text-align: center;
    padding: 0px 10px 0px 10px !important;
  }
  .banner-text-title {
    h1 {
      font-size: 50px;
    }
    h2 {
      font-size: 24px;
    }
  }
  .banner-text-subtitle {
    h5 {
      font-size: 18px;
    }
  }
  .service-item {
    min-height: 250px;
  }
  .service-content-title {
    min-height: 50px;
  }
  .carousel-image-container {
    height: 300px;
  }
}

@media only screen and (max-width: 576px) {
  .team-position-container {
    min-height: 150px;
  }
  .team-swiper {
    width: 100%;
  }
  .carousel-image-container {
    height: 250px;
  }
}
