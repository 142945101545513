.contract_widget {
  border-radius: var(--wg-border-radius);
  background-color: antiquewhite;
  text-align: center;
}

.contract_widget__content {
  padding: 30px 40px 180px;
  background-image: url("../../../../assets/img/features/sidebar_contact_bg.jpg");
  background-position: center;
  background-size: cover;
  border-radius: var(--wg-border-radius);
  flex-direction: column;
  display: flex;
  align-items: center;

  a {
    padding: 13px 24px;
    border-radius: 30px;
    white-space: nowrap;
    color: #fff;
    display: inline-flex;
    align-items: center;
    text-align: center;
    font-weight: 600;
    line-height: 1;
    background-color: var(--wg-primary-color);
    touch-action: manipulation;
    overflow: hidden;
    z-index: 1;
    text-decoration: none;
    cursor: pointer;
    gap: 7px;
    font-size: 13px;
    transition: background-color 360ms ease;
  }

  a:hover {
    background-color: var(--wg-secondary-color);
  }
}

.contract_widget__title {
  margin-bottom: 30px;
  font-size: 20px;
}
