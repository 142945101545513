$white-color: var(--bs-white);

.brochure-box {
  width: 100%;
  h4 {
    margin-bottom: 2px;
  }
}

.brochure-btn {
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 15px 20px;
  background-color: $white-color;
  color: var(--wg-secondary-color);
  border-radius: var(--wg-border-radius);
  transition: 360ms ease;

  font-weight: 600;
  span {
    display: flex;
    justify-content: flex-start;
    min-width: 25px;
    margin-right: 0px;
  }
}

.brochure-btn:hover {
  background-color: var(--wg-theme-secondary);
  color: $white-color;
}
