.social-widget-container {
  display: block;
  position: fixed;
  z-index: 99;
  right: 15px;
  bottom: 25%;

  a {
    display: flex;
    justify-content: center;
    text-decoration: none;
    padding: 0px;
    margin: 0px;
  }
}

$btnSize: 48px;

.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: $btnSize;
  min-height: $btnSize;
  width: $btnSize;
  height: $btnSize;
  border-radius: $btnSize / 2;
  text-align: center;

  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.btn-messenger {
  background-color: #168aff;
}

.btn-line {
  background-color: #06c755;
  * {
    margin-top: 2px;
  }
}

$animation: 280ms cubic-bezier(0.2, 0.29, 0.07, 1.64);
// $animation: 280ms cubic-bezier(0.39, 1.52, 0.74, 1.62);
.btn-pop {
  transform: scale(1);
  transition: $animation;
}

.btn-pop:hover {
  transform: scale(1.2);
}

.social-widget-container:hover {
  div:hover {
    transform: scale(1.2);
  }

  div:not(:hover) {
    transform: scale(0.8);
  }
}
